<!-- eslint-disable -->
<template>
  <v-container id="dashboard" fluid tag="section">
    <b-modal v-model="showPricesModal" @hidden="handleModalHide" hide-footer size="lg">
      <template #modal-title>
        Prix entre depots
      </template>
      <vue-good-table
        max-height="700px"
        :columns="columns2"
        :isLoading.sync="isLoading"
        :rows="rows2"
        :pagination-options="{enabled: true, perPageDropdown:[10, 20,50,100,200],perPage:15}"
        style-class="vgt-table condensed bordered tableOne"
      >
      <template slot="loadingContent">
          <div class="spinner spinner-primary mx-auto my-auto"></div>
      </template>
      <!-- <template slot="column-filter" slot-scope="props">
        <b-form-datepicker v-if="props.column.filterOptions.customFilterDate" @input="handleCustomFilterDate"></b-form-datepicker>
      </template> -->
      <template slot="table-row" slot-scope="props">
          {{props.row[props.column.field]}}
      </template>
      </vue-good-table>
    </b-modal>
    <b-modal v-model="showAddPriceModal" @hidden="handleModalHide" hide-footer size="lg">
      <template #modal-title>
        Ajouter un prix entre depots
      </template>
      <b-form @submit.prevent="addNewPrice">
        <v-row>
        <v-col cols="12" md="6">
          <label>Depot From</label>
          <!-- <multiselect required v-model="selectedWilaya1" tag-placeholder="Lieu de départ" placeholder="Lieu de départ" label="name" track-by="name" :options="wilayas" :multiple="false" :taggable="true" @input="updateSelectedWilaya1"></multiselect> -->
          <b-form-select required v-model="priceData.w1">
            <option v-for="wilaya in rows" :key="wilaya.wilaya" :value="wilaya.id">{{wilaya.wilaya}}-->{{wilaya.name}}</option>
          </b-form-select>
        </v-col>
        <v-col cols="12" md="6">
          <label>Depot to</label>
          <!-- <multiselect required v-model="selectedWilaya1" tag-placeholder="Lieu de départ" placeholder="Lieu de départ" label="name" track-by="name" :options="wilayas" :multiple="false" :taggable="true" @input="updateSelectedWilaya1"></multiselect> -->
          <b-form-select required v-model="priceData.w2">
            <option v-for="wilaya in rows" :key="wilaya.wilaya" :value="wilaya.id">{{wilaya.wilaya}}-->{{wilaya.name}}</option>
          </b-form-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field required v-model="priceData.delivery" label="Tarif" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field required v-model="priceData.stop_desk" label="Stop Desk" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field required v-model="priceData.retour" label="Retour" class="purple-input"/>
        </v-col>
        </v-row>
        <v-col cols="12" class="text-right">
          <v-btn type="submit" style="background-color:#4caf50" class="mr-0">
            Confirmer
          </v-btn>
        </v-col>
        </b-form>
    </b-modal>
    <b-modal v-model="showEditModal" @hidden="handleModalHide" hide-footer size="md">
      <template #modal-title>
        Changement des informations de {{packageDetail.name}}
      </template>
      <b-form @submit.prevent="adminChangeInfo">
        <v-row>
        <v-col cols="12" md="12">
          <v-text-field required v-model="packageDetail.email" label="Email" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field required v-model="packageDetail.password" label="Mot de passe" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field required v-model="packageDetail.name" label="Nom" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field required v-model="packageDetail.phone" label="Téléphone" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field required v-model="packageDetail.collect_fees" label="Prix ramassage" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field required v-model="packageDetail.home_fees" label="Prix domicile" class="purple-input"/>
        </v-col>
        </v-row>
        <v-col cols="12" class="text-right">
          <v-btn type="submit" style="background-color:#4caf50" class="mr-0">
            Confirmer
          </v-btn>
        </v-col>
        </b-form>
    </b-modal>
  <b-modal v-model="showLoginModal" @hidden="handleModalHide" hide-footer size="lg">
    <template #modal-title>
      Détail de poste
    </template>
    <b-popover
      target="popover-reactive-1"
      triggers="click"
      :show.sync="popoverShow"
      placement="auto"
      container="my-container"
      ref="popover"
    >
      <template #title>
        CONFIRMATION
      </template>
      <b-button @click="onClose" size="sm" style="margin-right:10px" variant="danger">Cancel</b-button>
      <b-button @click="deleteDepot" size="sm" variant="primary">Ok</b-button>
    </b-popover>
    <v-container class="py-0">
        <v-form @submit.prevent="changeFees">
        <v-row>
        <v-col cols="12" md="12">
          <v-text-field v-model="packageDetail.home_fees" type="number" label="Frais de livraison domicile" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <b-form-select style="margin-top:15px" v-model="packageDetail.dlv_type">
            <option value="both">post par défault</option>
            <option value="homedlv">post à domicile</option>
            <option value="stopdesk">post stop desk</option>
          </b-form-select>
        </v-col>
        <v-col cols="12" md="6" style="text-align:right">
          <v-btn small depressed style="background-color:#4caf50" @click="addFeesException" class="mr-0">Ajouter une exception de frais de transport</v-btn>
        </v-col>
        <v-row v-for="(feesException, index) in feesExceptions" :key="feesException.post_code" :value="feesException.post_code">
          <v-col cols="12" md="6">
          <label>Poste</label>
          <b-form-select style="margin-top:15px" v-model="feesException.post_code">
            <option v-for="post in rows" :key="post.code" :value="post.code">{{post.name}}->{{post.wilaya}}</option>
          </b-form-select>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field v-model="feesException.fees" type="number" label="Frais de livraison entre postes" class="purple-input"/>
          </v-col>
          <v-col cols="12" md="1">
            <b-button-close style="margin-top:20px" @click="removeFeesExeception(index)"/>
          </v-col>
        </v-row>
        <v-col cols="12" md="6" >
          <v-btn id="popover-reactive-1" style="background-color:#D50000" class="mr-0">
            SUPPRIMER
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" class="text-right">
          <v-btn type="submit" style="background-color:#4caf50" class="mr-0">
            Enregistrer
          </v-btn>

        </v-col>
        </v-row>
        </v-form>
    </v-container>
  </b-modal>
  <b-modal v-model="showPostModal" @hidden="handleModalHide" hide-footer size="lg">
    <template #modal-title>
      Ajouter un depôt
    </template>
    <div style="width:100%;text-align:center"><span style="color:red">{{newPostErrorMessage}}</span></div>
    <v-container class="py-0">
        <b-form @submit.prevent="addNewDepot">
        <v-row>
        <v-col cols="12" md="6">
          <v-text-field required v-model="newPost.email" label="Email" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field required v-model="newPost.password" label="Mot de passe" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field required v-model="newPost.name" label="Nom" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field required v-model="newPost.phone" placeholder="phone1/phone2" label="N° Téléphone" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <label>Wilaya</label>
          <b-form-select required v-model="selectedWilaya" @change="newPost.wilaya = selectedWilaya.name">
            <option v-for="wilaya in wilayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
          </b-form-select>
        </v-col>
        <v-col cols="12" md="6">
          <label>Commune</label>
          <b-form-select required v-model="selectedCommunue" @change="newPost.communue = selectedCommunue">
            <option v-for="communue in selectedWilaya.communues" :key="communue.name" :value="communue.name">{{communue.name}}</option>
          </b-form-select>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field required v-model="newPost.address" label="Adresse" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field required v-model="newPost.collect_fees" placeholder="0" type="number" label="Frais de ramassage" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field required v-model="newPost.home_fees" placeholder="0" type="number" label="Frais de livraison à domicile" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="3">
        </v-col>
        <v-col cols="12" md="3" style="margin-top:20px">
          <b-form-checkbox style="float:right;margin-left:20px" v-model="newPost.is_stop_desk" name="check-button" switch></b-form-checkbox>
          <label style="float:right">Stop Desk</label>
        </v-col>
        <!-- <v-col cols="12" md="6">
          <v-text-field required v-model="newPost.home_fees" type="number" label="Frais de livraison domicile" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="12" style="text-align:right">
          <v-btn small depressed style="background-color:#4caf50" @click="addFeesException" class="mr-0">Ajouter une exception de frais de transport</v-btn>
        </v-col>
        <v-row v-for="(feesException, index) in feesExceptions" :key="feesException.post_code" :value="feesException.post_code">
          <v-col cols="12" md="6">
          <label>Poste</label>
          <b-form-select style="margin-top:15px" v-model="feesException.post_code">
            <option v-for="post in rows" :key="post.code" :value="post.code">{{post.name}}->{{post.wilaya}}</option>
          </b-form-select>
          </v-col>
          <v-col cols="12" md="5">
            <label>Frais</label>
            <v-text-field v-model="feesException.fees" type="number" label="Frais de livraison entre postes" class="purple-input"/>
          </v-col>
          <v-col cols="12" md="1">
            <b-button-close style="margin-top:20px" @click="removeFeesExeception(index)"/>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn type="submit" style="background-color:#4caf50" class="mr-0">
              Ajouter
            </v-btn>
          </v-col>
        </v-row>
        </b-form>
    </v-container>
  </b-modal>
  <base-material-card icon="mdi-package-variant-closed" title="Liste des dépôts" class="px-5 py-3">
  <template v-slot:heading>
    <v-icon @click="reloadItems">mdi-reload</v-icon>
  </template>
   <div style="width:100%;text-align:right">
    <v-btn color="success" small depressed @click="showPricesModal = true;loadPrices();">Afficher liste des prix</v-btn>
     <v-btn color="success" small depressed @click="showAddPriceModal = true">Ajouter un prix</v-btn>
     <v-btn color="success" small depressed @click="showPostModal = true">Ajouter un depôt</v-btn>
   </div>
   <!-- @on-row-click="rowClick" -->
    <vue-good-table
      max-height="700px"
      :columns="columns"
      :isLoading.sync="isLoading"
      :rows="rows"
      :pagination-options="{enabled: true, perPageDropdown:[10, 20,50,100,200],perPage:15}"
      style-class="vgt-table condensed bordered tableOne"
    >
    <template slot="loadingContent">
        <div class="spinner spinner-primary mx-auto my-auto"></div>
    </template>
    <template slot="column-filter" slot-scope="props">
      <b-form-datepicker v-if="props.column.filterOptions.customFilterDate" @input="handleCustomFilterDate"></b-form-datepicker>
    </template>
    <template slot="table-row" slot-scope="props">
        <span style="color:red;" v-if="props.column.field == 'incoming_packages'">
          {{props.row[props.column.field]}}
        </span>
        <span  v-else-if="props.column.field == 'name'">
          {{props.row[props.column.field]}}
          <v-icon v-if="props.row.dlv_type == 'homedlv'" style="float:right;" color='green'>mdi-home</v-icon>
          <v-icon v-if="props.row.dlv_type == 'stopdesk'" style="float:right;" color='green'>mdi-bank-outline</v-icon>
        </span>
        <span v-else-if="props.column.field == 'password'">
        <b-button @click="copyPassword(props.row)" v-b-tooltip.hover :title="copyMessage" id="password-btn" style="background-color:#4caf50;color:white;" >
          <v-badge v-if="props.row.is_new_post" color="red" overlap bordered>
            <template v-slot:badge>
              <span>new</span>
            </template>
            {{props.row.password}}
          </v-badge>
          <span v-else>{{props.row.password}}</span>
        </b-button>
        <v-icon @click="showEdit(props.row)" style="color:blue;margin-left:10px;float:right">mdi-square-edit-outline</v-icon>
        </span>
        <span v-else-if="props.column.field == 'action'">
          <v-icon @click="goToPost(props.row)" style="color:blue;margin-left:10px;float:right">mdi-open-in-new</v-icon>
          <v-icon id="popover-reactive-1"  @click="showConfirmation(props.row)" style="color:#dc3545;margin-left:10px;">mdi-close-circle</v-icon>
          <b-popover
            target="popover-reactive-1"
            triggers=""
            :show.sync="popoverShow"
            placement="auto"
            container="my-container"
            ref="popover"
          >
            <template #title>
              CONFIRMATION
            </template>
            <b-button @click="onClose" size="sm" style="margin-right:10px" variant="danger">Cancel</b-button>
            <b-button @click="deleteDepot" size="sm" variant="primary">Ok</b-button>
          </b-popover>
        </span>
        <span style="color:#4caf50;" v-else-if="props.column.field == 'outgoing_packages'">
          {{props.row[props.column.field]}}
        </span>
        <span style="color:#4caf50;" v-else-if="props.column.field == 'is_stop_desk'">
          <b-form-checkbox style="float:right" v-model="props.row[props.column.field]" name="check-button" switch @change="makeStopDesk(props.row)"></b-form-checkbox>
        </span>
        <span v-else>
          {{props.row[props.column.field]}}
        </span>
    </template>
    </vue-good-table>
  </base-material-card>
  </v-container>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
export default {
  name: 'CenterDashboard',
  data () {
    return {
      // searchTerm: '',
      priceData: {
        w1:null,
        w2:null,
        delivery:null,
        stop_desk:null,
        retour:null
      },
      newPostErrorMessage: null,
      wilayas: [],
      communues: [],
      selectedWilaya: {
        name:null,
        communues: []
      },
      statusList: ['En attente', 'Accepté', 'Réfusé'],
      selectedCommunue: null,
      showPackageModal: false,
      showHisotryModal: false,
      isLoading: false,
      totalRows: 0,
      tableFilters: {
        approved: ''
      },
      outgoingTableFilters: {
        id: null,
        name: null,
        status: null,
        from_wilaya: null,
        date: null
      },
      history: {
        wilaya: null,
        communue: null,
        tracking_id: null,
        show: false,
        message: null,
        creation_date: {
          date: null,
          time: null
        }
      },
      packageDetail: {
        home_fees:0,
      },
      columns2:[
        {
          label: 'Depot De',
          field: 'depot1',
          width: '100px',
          filterOptions: {
            enabled:true,
            placeholder: 'Entrer le nom',
            // trigger: 'enter',
          }
        },
        {
          label: 'Depot à',
          field: 'depot2',
          width: '100px',
          filterOptions: {
            enabled:true,
            placeholder: 'Entrer le nom',
            // trigger: 'enter',
          }
        },
        {
          label: 'Tarif',
          field: 'delivery',
          width: '100px',
          filterOptions: {
            enabled:true,
            placeholder: 'Entrer le nom',
            // trigger: 'enter',
          }
        },
        {
          label: 'Stop Desk',
          field: 'stop_desk',
          width: '100px',
          filterOptions: {
            enabled:true,
            placeholder: 'Entrer le nom',
            // trigger: 'enter',
          }
        },
        {
          label: 'Retour',
          field: 'retour',
          width: '100px',
          filterOptions: {
            enabled:true,
            placeholder: 'Entrer le nom',
            // trigger: 'enter',
          }
        },
      ],
      columns:[
        {
          label: 'Nom',
          field: 'name',
          width: '100px',
          filterOptions: {
            enabled:true,
            placeholder: 'Entrer le nom',
            // trigger: 'enter',
          }
        },
        {
          label: 'Téléphone',
          field: 'phone',
          width: '100px',
          filterOptions: {
            enabled:true,
            placeholder: 'Enter le numéro',
            // trigger: 'enter',
          }
        },
        {
          label: 'Email',
          field: 'email',
          width: '100px',
          filterOptions: {
            enabled:true,
            placeholder: 'Enter le numéro',
            // trigger: 'enter',
          }
        },
        {
          label: 'Mot de passe',
          field: 'password',
          width: '100px',
          filterOptions: {
            enabled:true,
            placeholder: 'Enter le numéro',
            // trigger: 'enter',
          }
        },
        {
          label: 'Wilaya',
          field: 'wilaya',
          width: '100px',
          filterOptions: {
            enabled: true,
            placeholder: 'Choisir la wilaya',
            // trigger: 'enter',
          },
        },
        {
          label: 'Stop Desk',
          field: 'is_stop_desk',
          width: '100px',
        },
        {
          label: 'Frais de ramassage',
          field: 'collect_fees',
          width: '150px',
          filterOptions: {
            enabled: false,
            placeholder: 'Choisir la commune',
            // trigger: 'enter',
          },
        },
        {
          label: 'Action',
          field: 'action',
          width: '80px',
        },
        // {
        //   label: 'Entrants',
        //   field: 'incoming_packages',
        //   width: '100px',
        //   type:'number',
        //   filterOptions: {
        //     enabled: false,
        //     placeholder: 'Choisir la commune',
        //     // trigger: 'enter',
        //   },
        // },
        // {
        //   label: 'Sortants',
        //   field: 'outgoing_packages',
        //   width: '100px',
        //   type:'number',
        //   filterOptions: {
        //     enabled: false,
        //     placeholder: 'Choisir la commune',
        //     // trigger: 'enter',
        //   },
        // },
        // {
        //   label: 'Livrée',
        //   field: 'total_delivered',
        //   width: '100px',
        //   type:'number',
        //   filterOptions: {
        //     enabled: false,
        //     placeholder: 'Choisir la commune',
        //     // trigger: 'enter',
        //   },
        // },
        // {
        //   label: 'Returned',
        //   field: 'total_returned',
        //   width: '100px',
        //   type:'number',
        //   filterOptions: {
        //     enabled: false,
        //     placeholder: 'Choisir la commune',
        //     // trigger: 'enter',
        //   },
        // },
      ],
      newPost: {
        email:null,
        password:null,
        wilaya:null,
        communue:null,
        phone:null,
        address:null,
        name: null,
        home_fees: null,
        code: null
      },
      rows: [],
      rows2: [],
      outgoingRows: [],
      showLoginModal: false,
      showPostModal: false,
      showAddPriceModal:false,
      showPricesModal:false,
      preparedRow: null,
      preparedAction: null,
      feesExceptions: [],
      popoverShow : false,
      selectedRow:null,
      showEditModal:false,
      copyMessage: 'Cliquer pour copier'
    }
  },
  mounted() {
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
    this.getWilayas()
    this.loadDepots()
    this.loadPrices()
    // this.columns[4].filterOptions.filterDropdownItems = this.statusList
    this.$set(this.columns[4].filterOptions, 'filterDropdownItems', this.statusList);
  },
  methods: {
    makeStopDesk(row) {
      axios.post('make-stop-desk', {
        auth_token: localStorage.getItem('auth_token'),
        email : row.email,
        is_stop_desk: row.is_stop_desk

      }).then(response => {

      })
    },
    loadPrices() {
      axios.post('admin-get-btd', {
        auth_token: localStorage.getItem('auth_token'),
      }).then(response => {
        if (response.data.status == 'success')
          this.rows2 = response.data.data
      })
    },
    addNewPrice() {
      axios.post('admin-create-btd', {
        auth_token: localStorage.getItem('auth_token'),
        price_data: this.priceData
      }).then(response => {
        if (response.data.status == 'success') {
          this.priceData = {
            w1:null,
            w2:null,
            delivery:null,
            stop_desk:null,
            retour:null
          }
        }
      })
    },
    goToPost(packageDetail) {
      localStorage.setItem('admin_auth_token',localStorage.getItem('auth_token'))
      localStorage.setItem('auth_token',packageDetail.auth_token)
      window.location.replace("depot-dashboard");
    },
    adminChangeInfo() {
      axios.post('admin-change-profile', {
        auth_token: localStorage.getItem('auth_token'),
        user_info: {
          auth_token: this.packageDetail.auth_token,
          email:this.packageDetail.email,
          password:this.packageDetail.password,
          name: this.packageDetail.name,
          phone: this.packageDetail.phone,
          collect_fees:this.packageDetail.collect_fees,
          home_fees: this.packageDetail.home_fees,
          user_type: 'depot'
        }
      }).then(response => {
        this.showEditModal = false
        this.loadDepots()
      })
    },
    showEdit(row) {
      this.packageDetail = row
      this.showEditModal = true
    },
    copyPassword(row) {
      this.$clipboard(row.password);
      this.copyMessage = 'Copié!'
      setTimeout(() => {
        this.copyMessage = 'Cliquer pour copier'
      }, 2000);
    },
    reloadItems() {
      this.loadDepots()
      this.isLoading = true
    },
    showConfirmation(row) {
      this.popoverShow = true
      this.selectedRow = row
    },
    deleteDepot() {
      axios.post('delete-depot',{
        auth_token: localStorage.getItem('auth_token'),
        email: this.selectedRow.email
      }).then(response =>{
        this.loadDepots()
      })
    },
    onClose(){
      this.popoverShow = false
    },
    delete_post(){
      axios.post('delete-post',{auth_token: localStorage.getItem('auth_token'),
      code: this.packageDetail.code}).then(response =>{
        this.popoverShow = false
        this.showLoginModal = false
        this.loadDepots()
      })
    },
    getWilayas() {
      axios.post('get-wilayas').then(response => {
        if (response.data.status == 'success') {
          this.wilayas = response.data.data
          let wilayas = []
          this.wilayas.forEach(element => {
            wilayas.push(element.name)
          });
          this.$set(this.columns[2].filterOptions, 'filterDropdownItems', wilayas);
          // this.columns[2].filterOptions.filterDropdownItems = wilayas
        }
      })
    },
    prepareAction(row,action) {
      this.preparedRow = row
      this.preparedAction = action
      this.showLoginModal = true
    },
    confirmAction() {
      if (this.preparedAction == 'accept') this.acceptProvider(this.preparedRow)
      else if (this.preparedAction == 'refuse') this.refuseProvider(this.preparedRow)
      this.showLoginModal = false
      this.loadDepots()
    },
    handleModalHide(params) {
      this.showPackageModal = false
      this.showHisotryModal = false
      this.showPostModal = false
      this.history.show = false
      this.newPostErrorMessage = null
    },
    hideModal(params) {
      this.showPackageModal = false
      this.showHisotryModal = false
      this.showPostModal = false
      this.history.show = false
      this.newPostErrorMessage = null
    },
    loadDepots() {
      axios.post('admin-depots', {
        auth_token: localStorage.getItem('auth_token'),
      }).then(response => {
        if (response.data.status == 'success')
          this.rows = response.data.data
      })
    },
    rowClick(params) {
      // if (!this.showOrderWithDuplicates && params.event.target.localName !== "input" && params.event.target.localName !== "select" && params.event.target.localName !== "option" && params.event.target.className !== "vgt-checkbox-col" && params.event.target.className !== "dropdown-item" && params.event.target.type !== "button" && params.event.target.tagName !== "A") {
      this.packageDetail = params.row
      if (params.event.target.id == "status-btn") {
      }
      else if (params.event.target.id == "history-btn") {
        this.showHisotryModal = true
        this.history.tracking_id = params.row.tracking_id
      } else {
        this.showLoginModal = true
        this.feesExceptions = []
      }
    },
    changeStatus(row, tableType) {
      axios.post('center-change-status', {
        auth_token: localStorage.getItem('auth_token'),
        tracking_id: row.tracking_id,
        new_status: row.status
      }).then(response => {
        if (response.data.status == 'success') {
          if (tableType == 'incoming') this.loadIncomingItems()
          else if (tableType == 'outgoing') this.loadOutgoingItems()
        }
      })
    },
    onColumnFilter(params) {
      console.log(params)
      this.tableFilters = params.columnFilters
      if (params.columnFilters.approved_value == this.statusList[0]) this.tableFilters.approved = null
      else if (params.columnFilters.approved_value == this.statusList[1]) this.tableFilters.approved = true
      else if (params.columnFilters.approved_value == this.statusList[2]) this.tableFilters.approved = false
      else this.tableFilters.approved = ''
      this.loadDepots()
    },
    onSortChange(params) {
      let t = params[0].type
      let f = params[0].field
      this.rows.sort(function(a, b) {
        if (t == 'asc') {
          if ( a[f] < b[f] ){
            return -1;
          }
          if ( a[f] > b[f] ){
            return 1;
          }
        }
        else if (t == 'desc') {
          if ( a[f] < b[f] ){
            return 1;
          }
          if ( a[f] > b[f] ){
            return -1;
          }
        }
      });
    },
    onPageChange(params) {
      console.log(params)
    },
    onPerPageChange(params) {
      console.log(params)
    },
    // this.$set(this.columns[5].filterOptions, 'filterValue', '');
    refuseProvider(row) {
      axios.post('admin-refuse-provider', {
        auth_token: localStorage.getItem('auth_token'),
        email: row.email
      }).then(response => {
        if (response.data.status == 'success') {
          row.approved = false
        }
      })
    },
    acceptProvider(row) {
      axios.post('admin-accept-provider', {
        auth_token: localStorage.getItem('auth_token'),
        email: row.email
      }).then(response => {
        if (response.data.status == 'success') {
          row.approved = true
        }
      })
    },
    addFeesException() {
      this.feesExceptions.push({
        post_code: null,
        fees: null
      })
    },
    removeFeesExeception(index) {
      this.feesExceptions.splice(index,1)
    },
    changeFees() {
      axios.post('admin-change-post-fees', {
        auth_token: localStorage.getItem('auth_token'),
        code: this.packageDetail.code,
        home_fees: this.packageDetail.home_fees,
        fees_exceptions: this.feesExceptions,
        dlv_type: this.packageDetail.dlv_type,
      }).then(response => {
        if (response.data.status == 'success') {
          this.showLoginModal = false
          this.loadDepots()
        }
      })
    },
    addNewDepot() {
      axios.post('admin-add-depot', {
        auth_token: localStorage.getItem('auth_token'),
        post_info: this.newPost,
        fees_exceptions: this.feesExceptions
      }).then(response => {
        if (response.data.status == 'success') {
          this.loadDepots()
          this.showPostModal = false
          this.newPost= {
            email:null,
            password:null,
            wilaya:null,
            communue:null,
            phone:null,
            address:null,
            name: null,
            home_fees: null,
            code: null
          }
          this.feesExceptions = []
          this.newPostErrorMessage = null
        } else {
          this.newPostErrorMessage = response.data.error_message
        }
      })
    },
  },
}
</script>
<style>
.vgt-responsive {
  min-height: 450px;
}
</style>
